.select-menu {
    display: inline-block;
    position: relative;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    appearance: none; /* Removes default arrow */
  }
  
  /* This styles the dropdown arrow */
  .select-menu:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
  }
  
  /* This styles the dropdown options */
  .select-menu option {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    background-color: #f9f9f9;
  }

  .item{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .addphoto{
    width: fit-content;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 15vh;
  }