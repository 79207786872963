:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    hsl(187, 85%, 43%),
    hsl(199, 100%, 33%)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
}

.reset-container {
  height: 60vh;
  background: transparent !important;
  transform: translateX(15vh);
}
.reset-container::before {
  content: "";
  position: absolute;
  width: 274.5px;
  height: 274.5px;
  background-image: url("../../Assets/forget.svg");
  background-size: 55vh;
  background-position: 10% 10% 10% 10%;
  background-position-x: left;
  background-position-y: bottom;
  border: solid 2px var(--blackColor);
  box-shadow: 0 25px 25px rgba(48, 48, 48, 0.5);
  transform: translate(100px, 140px);
}
.reset-container .reset-form-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  background: transparent !important;
  position: relative;
  z-index: 1000;
  width: 100%;
  max-width: 1000px;
  padding: 50px;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 25px 25px rgba(48, 48, 48, 0.5);
  border: 3px solid rgba(255, 255, 255, 0.25);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  overflow: hidden;
  backdrop-filter: blur(20px);
  transform: translate(55vh, 20vh);
  width: fit-content;
}
.reset-container form {
  width: 500px;
}
