:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    hsl(187, 85%, 43%),
    hsl(199, 100%, 33%)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
  --cardHover: rgb(255, 255, 255);
}
.sidebar {
  height: fit-content !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.55);
}
.sidebar-brand {
  margin-top: 10%;
}
.sidebar-brand .align-middle {
  font-size: 22px;
  font-weight: 600;
}
.admin-hr {
  border: solid 3px var(--whiteColorDream);
}
.sidebar-header {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 3%;
}
.sidebar-header .actions-icon {
  font-size: 25px;
  margin-left: 2%;
  margin-bottom: 2%;
}
.sidebar-item {
  margin-bottom: 8%;
}
.sidebar-item .sidebar-link {
  text-align: start;
}
.sidebar-item .align-middle {
  font-size: 20px;
  font-weight: 600;
}
.sidebar-item .align-middle:hover {
  font-size: 20px;
  font-weight: 600;
  color: var(--whiteColor);
}
