:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    hsl(187, 85%, 43%),
    hsl(199, 100%, 33%)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
}

.home {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.home .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(47, 106, 127);
  bottom: o;
  top: 0;
  z-index: 1;
  mix-blend-mode: hard-light;
}
.home video {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.home .homeContent {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  padding: 8rem 1.5rem 3rem;
  display: flex;
  row-gap: 3rem;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: auto;
  color: var(--whiteColor);
  z-index: 10;
}
.home .homeContent .textDiv {
  padding: 2rem 0;
}
.home .homeContent .textDiv .smallText {
  font-size: 25px;
  font-weight: 400;
  text-transform: uppercase;
}
.home .homeContent .textDiv .homeTitle {
  position: relative;
  font-size: 3rem;
  color: var(--whiteColor);
  font-weight: 600;
}
.home .homeContent .cardDiv {
  position: relative;
  padding: 2rem;
  background: var(--whiteColor);
  border-radius: 10px;
  gap: 1rem;
}
.home .homeContent .cardDiv label {
  display: block;
  color: var(--textColor);
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}
.home .homeContent .cardDiv .label_total {
  justify-content: space-between;
  align-items: center;
}
.home .homeContent .cardDiv .label_total .total {
  color: var(--blackColor) !important;
  font-size: 18px;
  padding-bottom: 10px;
}
.home .homeContent .cardDiv .destinationInput .input .icon-search {
  font-size: 25px;
  color: var(--blackColor);
  cursor: pointer;
}
.home .homeContent .cardDiv .input {
  height: 40px;
  padding: 0 1rem;
  background: var(--inputColor);
  border-radius: 3rem;
}
.home .homeContent .cardDiv .input input {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
}
.home .homeContent .cardDiv .input input::-moz-placeholder {
  font-size: 13px;
}
.home .homeContent .cardDiv .input input::placeholder {
  font-size: 13px;
}
.home .homeContent .cardDiv .input .icon {
  font-size: 18px;
  color: var(--PrimaryColor) !important;
  margin-left: 15px;
}
.home .homeContent .cardDiv .input .input[type="range"] {
  background: cyan;
}
.home .homeContent .cardDiv .searchOptions {
  position: absolute;
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  width: -moz-max-content;
  width: 200px;
  bottom: -0.7rem;
  -moz-column-gap: 0.8rem;
  column-gap: 0.8rem;
  padding: 5px 1rem;
  border-radius: 3rem;
  left: 50%;
  transform: translate(-50%);
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
}
.home .homeContent .cardDiv .searchOptions:hover {
  background: #ff416c;
}
.home .homeContent .cardDiv .searchOptions .link-to-all {
  text-decoration: none;
  color: var(--whiteColor) !important;
}
.home .homeContent .cardDiv .searchOptions .icon {
  font-size: 18px;
}
.home .homeContent .cardDiv .searchOptions .link-to-all .view-all-icon {
  font-size: 30px;
}
.home .homeContent .cardDiv .searchOptions span {
  font-size: 13px;
}
.home .homeContent .homeFooterIcons {
  width: 100%;
  justify-content: space-between;
}
.home .homeContent .icon {
  font-size: 40px;
  margin: 0 5px;
  color: var(--whiteColor);
  font-weight: 600;
}
.home .homeContent .icon:hover {
  transform: translateY(-7px);
  transition: 0.3s ease-in-out;
} /*# sourceMappingURL=home.css.map */
.view-all-page-span {
  color: #ff416c;
  padding: 5px;
  border: none;
  border-radius: 15px 15px 15px;
  background: var(--whiteColor);
}
