:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    hsl(187, 85%, 43%),
    hsl(199, 100%, 33%)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
  --cardHover: rgb(255, 255, 255);
}
.site-screen-content {
  transform: translateY(130px);
  height: 185vh;
  display: flex;
  flex-direction: column;
}
.site-screen-content .site-screen-post {
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 10px;
  background: var(--cardBG);
  box-shadow: 0 2px 4px rgba(55, 55, 55, 0.649);
  overflow: hidden;
}
.site-screen-content .site-screen-post:hover {
  background: var(--cardHover);
}
.site-screen-content .site-screen-post .site-screen-image {
  height: 350px;
  width: 530px;
  overflow: hidden;
  transform: translateX(-10px);
}
.site-screen-content .site-screen-post .site-screen-image img {
  height: 350px;
  width: 530px;
  -o-object-fit: cover;
  object-fit: cover;
  transition: 2s ease-in-out;
}
.site-screen-content .site-screen-post .site-screen-image img:hover {
  cursor: pointer;
}
.site-screen-content .site-screen-post .site-screen-info1 {
  width: 820px;
  height: 350px;
  overflow: hidden;
  background: transparent;
}
.site-screen-content .site-screen-post .site-screen-info1 .site-details1 {
  display: flex;
  flex-direction: column;
  background: transparent;
  gap: 20px;
  padding-left: 10px;
  padding-right: 20px;
}
.site-screen-content .site-screen-post .site-screen-info1 .site-details1 h4 {
  font-size: 35px;
  color: var(--blackColor);
  font-weight: 600;
  transform: translateY(2px);
}
.site-details-hr {
  border: 2px solid var(--textColor);
  transform: translateY(-22px);
  width: 830px;
}
.site-details-category {
  color: var(--textColor);
  max-width: 300px;
  font-weight: 600;
  font-size: 25px;
  align-self: center;
  transform: translateY(-25px);
}
.site-details-category small {
  background: var(--gradientColor);
  border-radius: 1rem;
  padding: 1px 10px;
  color: var(--whiteColor);
  font-size: 15px;
}
.site-details-fees {
  display: flex;
  font-weight: 600;
  font-size: 25px;
  transform: translateY(-30px);
  color: var(--whiteColor);
}
.site-details-fees h5 {
  font-weight: 600;
  font-size: 25px;
  color: var(--textColor);
  transform: translateY(10px);
}
.site-details-description {
  transform: translateY(-55px);
  font-size: 13px;
  color: var(--textColor);
  font-weight: 600;
}
.site-details-description h5 {
  color: var(--blackColor);
  font-size: 22px;
  font-weight: 600;
  text-decoration: underline;
}
.site-details-top {
  display: flex;
  justify-content: space-between;
}
.site-details-top .site-details-rating {
  align-self: center;
  font-size: 20px;
}
.site-details-cat-fees {
  display: flex;
  justify-content: space-between;
}
.site-details-location {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transform: translateY(-32px);
  margin-bottom: 2%;
}
.site-details-btn {
  color: var(--whiteColor);
  font-weight: 700;
}
.site-details-btn:hover {
  color: var(--blackColor);
}
.site-details-images-carousel {
  height: 100%;
  width: 100%;
}
/* comments section */
.comments-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 4%;
}
.comments-section .comments-container {
  display: flex;
  flex-direction: column;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 10px;
  width: 850px;
  background: var(--cardBG);
  box-shadow: 0 2px 4px rgba(55, 55, 55, 0.649);
  overflow: hidden;
}
.comments-section .comments-container:hover {
  background: var(--cardHover);
}
.comments-section .comments-container .comments-section-title {
  color: var(--blackColor);
  font-weight: 600;
  margin-left: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 2%;
  font-size: 25px;
  display: flex;
  justify-content: space-between;
}
.comments-section .comments-container .scrolling-through-comments {
  height: 58vh;
  overflow: scroll;
}
.comments-section .comments-container .all-comments {
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
  border-radius: 10px 10px 10px;
  background: #3d3d3d;
}
.comments-section .comments-container .all-comments .a-comment {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.comments-section .comments-container .all-comments .a-comment .comment-pfp {
  width: 60px;
  padding-top: 1%;
  padding-left: 1%;
}
.comment-info {
  display: flex;
  flex-direction: column;
  width: 800px;
}
.comment-info .comment-user-date {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  transform: translateY(18px);
  color: var(--whiteColorDream);
}
.comment-info .comment-user-date .comment-username {
  font-size: 20px;
}
.comment-info .comment-textmedia-and-button {
  display: flex;
  flex-direction: column;
}
.comment-info .comment-textmedia-and-button .comment-text-and-media {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.comment-info
  .comment-textmedia-and-button
  .comment-text-and-media
  .comment-text-white {
  font-weight: 300;
  color: var(--whiteColorDream);
  margin-top: 25px;
  height: fit-content;
  margin-bottom: 2%;
}
.comment-info
  .comment-textmedia-and-button
  .comment-text-and-media
  .comment-media-image {
  width: 260px;
  height: 190px;
  object-fit: cover;
  border-radius: 10px 10px 10px;
  box-shadow: rgba(132, 132, 132, 0.25) 0px 54px 55px,
    rgba(179, 179, 179, 0.25) 0px -12px 30px,
    rgba(179, 179, 179, 0.25) 0px 4px 6px,
    rgba(179, 179, 179, 0.25) 0px 12px 13px,
    rgba(179, 179, 179, 0.25) 0px -3px 5px;
}
.delete-comment-button {
  display: flex;
  justify-content: end;
  padding-right: 10px;
  margin-bottom: 10px;
}
.trash-comment-icon {
  font-size: 35px;
  color: var(--whiteColorDream);
}
.trash-comment-icon:hover {
  background: none;
  color: #ff4757;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.add-comment-div {
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
  border-radius: 10px 10px 10px;
  background: #3d3d3d;
  display: flex;
  justify-content: center;
}
.add-comment-div .add-comment-group {
  padding-top: 20px;
}
.add-comment-div .add-comment-input {
  width: 580px;
  height: 55px;
  background-color: var(--whiteColor);
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  border: none;
  color: var(--blackColor);
  font-weight: 500;
  padding-left: 40px;
}
.comment-and-post {
  align-self: center;
}
.comment-and-post span {
  cursor: pointer;
  float: right;
  padding-right: 2%;
  transform: translateY(-44px);
}
.comment-and-post .post-comment-icon {
  font-size: 35px;
  color: var(--blackColor);
}
.add-image-in-comment {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
}
.adding-media-to-comment {
  font-size: 15px;
  font-weight: 500;
  border-radius: 50px;
  width: 380px;
  outline: none;
  transform: translateX(18px);
  border: none;
}
.message-if-not-logged {
  align-self: center;
  font-weight: 600;
  color: var(--whiteColorDream);
  margin-top: 15px;
  margin-bottom: 15px;
}
.camera-comment {
  font-size: 25px;
  color: var(--whiteColorDream);
}
.details-rating-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 10px;
  width: 600px;
  background: var(--cardBG);
  box-shadow: 0 2px 4px rgba(55, 55, 55, 0.649);
  overflow: hidden;
  margin-top: 30px;
  height: 150px;
  transform: translateX(47vh);
}
.details-rating-div:hover {
  background: var(--cardHover);
}
.details-rating-text {
  color: var(--whiteColorDream);
  width: 580px;
  height: 130px;
  justify-self: center;
  background: #3d3d3d;
  margin-top: 1%;
  padding-top: 3%;
  border-radius: 10px;
  text-align: center;
  text-justify: center;
  font-weight: 700;
  font-size: 25px;
}
.fav-icon {
  color: #ff4757;
}
.comment-user-pfppppp {
  height: 50px;
  object-fit: cover;
}
