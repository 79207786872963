/*.social-ul{
    position: relative;
    display: flex;
    z-index: 2;
}

.social-ul .social-li{
    position: relative;
    list-style: none;
    margin: 10px;
}

.social-ul .social-li .socials{
    position: relative;
    width: 40px;
    height: 40px;
    display: inline-block;
    border: 1px solid rgba(255, 255, 255,0.4);
    border-right: 1px solid rgba(255, 255, 255,0.2);
    border-bottom: 1px solid rgba(255, 255, 255,0.2);
    display: flex;
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0));
    justify-content: center;
    align-items: center;
    font-size: 2em;
    color: #fff;
    border-radius: 10px;
    text-decoration: none;
    backdrop-filter: blur(2px);
    transition: 0.5s;
    overflow: hidden;
}

.social-ul .social-li .socials:hover{
    transform: translateY(-20px);
}

.social-ul .social-li .socials::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    background: rgba(255, 255, 255,0.5);
    transform: skewX(45deg) translateX(150px);
    transition: 0.5s;
}

.social-ul .social-li .socials:hover::before{
    transform: skewX(45deg) translateX(-150px);
}*/

.social-div{
    margin: 0;
    padding: 0;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
  .social-ul{
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
  }
  .social-ul .social-li{
    position: relative;
    list-style: none;
    width: 40px;
    height: 40px;
    margin: 0 15px;
    transform: rotate(-30deg) skew(25deg);
    background: #ccc;
  }
  .social-ul .social-li span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    transition: 0.5s;
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 30px !important;
  }
  .social-ul .social-li:hover span:nth-child(5){
    transform: translate(40px, -40px);
    opacity: 1;
  }
  .social-ul .social-li:hover span:nth-child(4){
    transform: translate(30px, -30px);
    opacity: .8;
  }
  .social-ul .social-li:hover span:nth-child(3){
    transform: translate(20px, -20px);
    opacity: .6;
  }
  .social-ul .social-li:hover span:nth-child(2){
    transform: translate(10px, -10px);
    opacity: .4;
  }
  .social-ul .social-li:hover span:nth-child(1){
    transform: translate(0px, -0px);
    opacity: .2;
  }
  .social-ul .social-li:nth-child(1) span{
    background: #4267b2;
  }
  .social-ul .social-li:nth-child(2) span{
    background: linear-gradient(45deg, #405DE6, #5851DB, #833AB4, #c3488e, #E1306C);
  }
  .social-ul .social-li:nth-child(3) span{
    background: #1DA1F2;
  }
  .social-ul .social-li:nth-child(4) span{
    background: #363dc7;
  }
  .social-ul .social-li:nth-child(5) span{
    background: #12aa4a;
  }

  /* "socials" animation */
  .rotate-vert-center{
    -webkit-animation:rotate-vert-center 2s cubic-bezier(.455,.03,.515,.955) 1s infinite both;animation:rotate-vert-center 2s cubic-bezier(.455,.03,.515,.955) 1s infinite both;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .socials-icon{
    font-size: 30px;
    margin-bottom: 20px;
  }
  .rotate-vert-center .h4{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @-webkit-keyframes rotate-vert-center{0%{-webkit-transform:rotateY(0);transform:rotateY(0)}100%{-webkit-transform:rotateY(360deg);transform:rotateY(360deg)}}@keyframes rotate-vert-center{0%{-webkit-transform:rotateY(0);transform:rotateY(0)}100%{-webkit-transform:rotateY(360deg);transform:rotateY(360deg)}}
  /* "socials" animation */