:root {
  --PrimaryColor: hsl(199, 100%, 33%);
  --SecondaryColor: hsl(187, 85%, 43%);
  --gradientColor: linear-gradient(
    to right,
    hsl(187, 85%, 43%),
    hsl(199, 100%, 33%)
  );
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDream: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
}

.add {
  transform: translate(20px, 170px);
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 10rem;
  display: flex;
  gap: 20px;
  background: rgba(72, 93, 92, 0.2);
  box-shadow: 20px 20px 20px rgba(48, 48, 48, 0.5);
  border-radius: 15px;
  width: 90%;
}
.add .content {
  border-right: 6px solid var(--whiteColor);
  flex: 5;
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  gap: 20px;
}
.add .content h1 {
  font-size: 50px;
  color: var(--blackColor);
  font-size: 600;
}
.add .content .add-input {
  max-width: 600px;
  width: 100%;
  height: 55px;
  background-color: var(--whiteColor);
  margin: 10px 0;
  border-radius: 55px;
  border: 2px solid var(--greyText);
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 1.5rem;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--textColor);
  outline: none;
}
.add .content .add-input::placeholder {
  color: var(--textColor);
  font-weight: 500;
}
.add .content .editorContainer {
  height: 300px;
  overflow: hidden;
  border: 2px solid var(--greyText);
  border-radius: 25px 25px 25px;
}
.add .content .editorContainer .editor {
  height: 100%;
  border: none;
  background-color: var(--whiteColor);
  font-size: 30px;
  font-weight: 600;
  color: var(--textColor);
}
.add .content .buttons {
  width: 200px;
  align-self: center;
  color: var(--whiteColor);
  font-weight: 600;
}
.add .content .buttons:hover {
  background: var(--PrimaryColor);
}
.edit-button {
  width: 200px;
  align-self: center;
  color: var(--whiteColor);
  font-weight: 600;
}
.edit-button:hover {
  background: var(--PrimaryColor);
  color: var(--whiteColor);
}

/* menu */
.add .sp-menu {
  border: none;
  flex: 2;
  display: flex;
  flex-direction: column;
  height: fit-content;
}
.add .sp-menu .sp-item {
  border: none;
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 20px;
  color: #555;
  margin-left: 0.5rem;
}
.add .sp-menu .sp-item .sp-img-h1 {
  font-size: 50px;
  color: var(--blackColor);
  font-size: 600;
  margin-top: 2.3rem;
  height: 100px;
}
.add .sp-menu .sp-item h1 {
  font-size: 50px;
  color: var(--blackColor);
  font-size: 600;
  margin-top: 1.6rem;
  margin-bottom: 2.5rem;
}
.add .sp-menu .sp-item .file {
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 10rem;
  margin-left: 1.5rem;
  transform: translateY(-3rem);
}
input[type="radio"] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
  margin-right: 1rem;
  cursor: pointer;
  margin-left: 1.5rem;
}
.add .sp-menu .sp-item .cat {
  display: flex;
  align-items: center;
  gap: 2px;
  color: var(--textColor);
  cursor: pointer;
  font-weight: 500;
}

.edit {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-left: 2%;
  padding: 0%;
}
.edit h1 {
  margin-left: 6.7%;
  color: var(--blackColor);
  font-weight: 600;
  margin-bottom: 15px;
}

.dr {
  position: relative;
}
.dr .edit-site-label {
  color: var(--textColor);
  font-weight: 500;
  margin-right: 2%;
  margin-left: 6.7%;
}
.dr label {
  color: var(--textColor);
  font-weight: 500;
  margin-right: 2%;
  margin-left: 8.5%;
}
.edit-content {
  transform: translateY(10px);
}
.add-photo {
  transform: translateY(-10vh);
  display: flex;
  flex-direction: column;
}
.add-photo .add-photo-title {
  margin-left: 8.5%;
  color: var(--blackColor);
  font-weight: 600;
}
.addphoto {
  transform: translate(-600px, 30px);
  background: transparent !important;
  border-radius: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
}
.addphoto input {
  margin-left: 30%;
  margin-bottom: 2.5%;
}
.upload-additional-h1 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}
.upload-additional-button {
  width: 100%;
  color: var(--whiteColor);
  font-weight: 500;
}
.upload-additional-button:hover {
  background: var(--PrimaryColor);
  color: var(--whiteColor);
}
.add-additional-image-input {
  font-weight: 500;
}
/*# sourceMappingURL=Write.css.map */
.sp-edit-password {
  margin-bottom: 5%;
}
