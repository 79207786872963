.notFound{
    width: 100%;
    height: 80vh;
    position: relative;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.notFound h1{
    font-size: 150px;
    font-weight: 600;
    color: var(--blackColor);
    text-align: center;
}

.notFound h2{
    color: var(--blackColor);
}

.notFound p{
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 5vh;
}

.wobble-hor-bottom{animation:wobble-hor-bottom 1.5s 1s infinite both}

@keyframes wobble-hor-bottom{0%,100%{transform:translateX(0);transform-origin:50% 50%}15%{transform:translateX(-30px) rotate(-6deg)}30%{transform:translateX(15px) rotate(6deg)}45%{transform:translateX(-15px) rotate(-3.6deg)}60%{transform:translateX(9px) rotate(2.4deg)}75%{transform:translateX(-6px) rotate(-1.2deg)}}